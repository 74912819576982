.inputLabelWrap {
    @apply grid grid-cols-2 gap-2
}

.inputLabel {
    @apply h-12 leading-[48px] align-middle rounded-lg w-full border-none bg-cardBgColor text-[#8C8C8C] flex justify-center
}

.selcted {
    @apply text-pointColor bg-black font-Pretendard_Bold
}

.animation-click {
    @apply transition duration-200 ease-in-out active:scale-95
}