@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Pretendard-Regular;
  @apply flex flex-col items-center bg-mainBgColor
}

#root {
  @apply w-full sm:w-[640px]
}


@layer base {
  body {
    @apply bg-mainBgColor text-white w-[100%] min-h-screen;
  }

  .layout {
    @apply flex flex-col justify-between w-[100%] min-h-screen;
  }

  .pageContainer {
    @apply py-5 flex flex-col;
  }

  .cardWrap {
    @apply bg-cardBgColor rounded-lg;
  }
}

@layer components {
  .selectedNavBtn {
    @apply justify-center flex flex-col gap-1 items-center size-12 text-pointColor;
  }

  .navBtn {
    @apply justify-center flex flex-col gap-1 items-center size-12 transition-colors cursor-pointer;
  }

  .radioBtn {
    @apply appearance-none size-3 mr-6 rounded-full bg-[#212121] checked:bg-pointColor ring-4 ring-[#212121] ring-offset-2 ring-offset-[#212121];
  }
}

.btn {
  @apply inline-block border-none hover:brightness-75;
}

@font-face {
  font-family: "Pretendard-Thin";
  src: url("./assets/fonts/Pretendard-Thin.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("./assets/fonts/Pretendard-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Pretendard-Medium";
  src: url("./assets/fonts/Pretendard-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Pretendard-SemiBold";
  src: url("./assets/fonts/Pretendard-SemiBold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Pretendard-Bold";
  src: url("./assets/fonts/Pretendard-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Helvetica";
  src: url("./assets/fonts/Helvetica_Condensed_Black.otf");
  font-weight: 400;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul {
  list-style: inside;
}