.week-isToday-weekday {
    @apply bg-pointColor rounded-t-xl text-black
}

.week-isTody-day {
    @apply w-10 rounded-b-xl bg-pointColor !text-black font-Pretendard_Bold
}

.month-active-day {
    @apply bg-pointColor rounded-xl !text-black font-Pretendard_Bold transition-colors
}